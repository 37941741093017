.logo {
    pointer-events: none;
    height: auto;
    width: 100%;
    position: absolute;
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  animation-name: opacity;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
  animation-iteration-count: 1;
}
  
@keyframes opacity {
  0% {
    opacity: 0;
    transform: translate(1500px);
  }
  100% {
    transform: translate(0px);
    opacity: 1;
  }
}