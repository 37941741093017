.contact { 
    position: fixed;
    bottom: 0;
    right: 0;
    color: var(--textPrimary);
    margin: 15px;
}

.contact:hover {
    color: var(--hoverTextPrimary)
}