a {
    border: none;
    outline:none;
}

.social-icons {
    color: var(--textPrimary);
    display:flex;
    margin-top: 5px;
    font-size: 2rem;
    height: 2rem;  
}

.social-icons:hover {
    color: var(--hoverTextPrimary)
}

.icon-wrapper {
    position: fixed;
    left: 0;
    z-index: 4;
    margin-top: 15px;
    margin-left: 15px;
}

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
     .social-icons {
        font-size: 2rem;
        height: 2rem;  
     }
     .icon-wrapper {
         display: flex;
         justify-content: space-evenly;
         width: 100%;
         margin: unset;
         margin-top: 15px;
     }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 /* @media only screen and (min-width: 600px) {
    .social-icons {
        font-size: 2;     
    }
 } */
 
 /* Medium devices (landscape tablets, 768px and up) */
 /* @media only screen and (min-width: 768px) {
    .social-icons {
        font-size: 2;     
    }
 } */
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .social-icons {
        font-size: 2rem;
        height: 2rem;    
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 /* @media only screen and (min-width: 1200px) {
    .social-icons {
        font-size: 2;     
    }
 }  */