.App {
  text-align: center;
}
  
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.txt {
  font-size: 15px;
  font-family: Impact, Charcoal, sans-serif;
  position: fixed;
  bottom: 15px;
}

.social-links {
  animation-name: social-links-slide;
  animation-duration: 2s;
}

nav {
  z-index: 1;
}

section {
  z-index: 2;
}

@keyframes social-links-slide {
  from {
    transform: translateX(-200px);
  }
  to {
    transform: translateX(0px);
  }
}
